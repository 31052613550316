
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import CountriesList from "@/core/constants/Countries";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);
    const registration = Yup.object().shape({
      clinic_name: Yup.string().required().label("Clinic name"),
      clinic_address_line_1: Yup.string().required().label("Clinic address"),
      clinic_city: Yup.string().required().label("Clinic city"),
      clinic_country: Yup.string().required().label("Clinic country"),
      clinic_email: Yup.string().required().email().label("Clinic email"),
      clinic_phone: Yup.string().required().label("Clinic phone"),
      clinic_zip: Yup.string().required().label("Clinic ZIP"),

      user_firstname: Yup.string().required().label("First Name"),
      user_lastname: Yup.string().required().label("Last Name"),
      user_email: Yup.string().required().email().label("Email"),
      user_password: Yup.string()
        .min(8)
        .matches(/^(?=.*[a-zA-Z])/, "Password must contain at least one letter and one number")
        .matches(/^(?=.*[0-9])/, "Password must contain at least one letter and one number")
        .required()
        .label("Password"),
      user_password_confirmation: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("user_password"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const onSubmitRegister = (values) => {
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT);

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      
      const clinic = {
        name: values.clinic_name,
        email: values.clinic_email,
        phone: values.clinic_phone,
        // operationPrice: 7500,
        // operationCurrency: "EUR",
        addresses: [ 
          {
            label: "DEFAULT",
            street: values.clinic_address_line_1,
            street2: values.clinic_address_line_2,
            city: values.clinic_city,
            zip: values.clinic_zip,
            state: values.clinic_state,
            country: values.clinic_country,
          },
        ],
        // appearOnSite: true,
        // allowCallbackQuestion: true,

        // Default user created with the clinic (admin)
        user: {
          firstname: values.user_firstname,
          lastname: values.user_lastname,
          email: values.user_email,
          password: values.user_password,
        },
      };

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      ApiService.post("/auth/register-clinic", clinic)
        .then(() => {
          Swal.fire({
            title: "Account created!",
            text: `We sent you an email at ${values.user_email} to confirm your account.`,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(() => {
            router.push({ name: 'sign-in' });
          });

          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        })
        .catch((err) => {
          console.error(err);
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
  data() {
    return {
			countriesList: CountriesList,
    };
  },
});
